import getConfig from 'next/config';

export { getServerCookie } from './CookieUtils';

export const {
  publicRuntimeConfig: {
    CDN_STATIC_ASSETS,
    ENVIRONMENT_NAME,
    MIGRATED_PUBLISH_SECTIONS,
    BASE_URL,
    DISPLAY_NAVIGATION_MENU,
    API_CLIENT_TIMEOUT,
    DFP_PHOTOBROWSER_REFRESH_THRESHOLD,
  },
} = getConfig();
